<template>
  <CCard>
    <CCardHeader class="d-flex">
      <TMessage content="Tracking list" bold class="my-auto" />
    </CCardHeader>
    <CCardBody class="w-100 mx-auto" style="max-width: 800px" v-if="trackings">
      <div v-for="tracking in trackings" :key="tracking.id">
        <CCard accentColor="primary">
          <CCardBody class="p-2 d-flex">
            <SMessageTracking
              :value="tracking.code"
              :checked="tracking.received"
            />
            <TMessageDateTime
              class="ml-auto"
              :content="tracking.updated_at"
              small
            />
          </CCardBody>
        </CCard>
      </div>
      <TMessageNoData v-if="!trackings.length" />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    trackings: {
      type: Array,
      required: false,
    },
  },
};
</script>
