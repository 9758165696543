<template>
  <TTabs :tabs="tabs" :activeTab="$route.query.tab" @change-tab="changeTab">
    <template #detail>
      <Order
        orderStore="order_wholesale"
        orderItemStore="order_items_wholesale"
      />
    </template>
    <template #lading_bill>
      <SCardLadingBillOfOrder :order="wholesale" />
    </template>
    <template #logs>
      <SCardLog :logableId="id" />
    </template>
    <template #transactions>
      <SCardTransaction :objectableId="id" />
    </template>
    <template #shipment_info>
      <SCardShipmentInfo :orderId="id" :userId="wholesale.customer_id" />
    </template>
    <template #contract>
      <SCardContract :orderId="id" />
    </template>
    <template #owning_box>
      <SCardOwningBoxOfOrder
        :owningBoxes="wholesale.owning_boxes_from_origin"
        :orderItems="items"
      />
    </template>
    <template #credit_card>
      <SCardCreditOrder :order="wholesale" orderStore="order_wholesale" />
    </template>
    <template #compensation>
      <SCardCompensationOrder :orderId="id" />
    </template>
    <template #tracking>
      <Tracking :trackings="wholesale.related_trackings" />
    </template>
    <template #tracking-badge>
      <CBadge color="primary">{{ wholesale.related_trackings.length }}</CBadge>
    </template>
    <template #lading_bill-badge>
      <CBadge color="primary">{{ ladingBills.length }}</CBadge>
    </template>
    <template #owning_box-badge>
      <CBadge color="primary">{{
        wholesale.owning_boxes_from_origin.length
      }}</CBadge>
    </template>
  </TTabs>
</template>
<script>
import { mapGetters } from "vuex";
import Order from "../components/Order.vue";
import Tracking from "../components/Tracking.vue";

export default {
  components: {
    Order,
    Tracking,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Order detail", icon: "cis-library-books" },
        { key: "shipment_info", name: "Shipment info", icon: "cis-map" },
        { key: "contract", name: "Contract", icon: "cis-document" },
        { key: "credit_card", name: "Credit card", icon: "cis-credit-card" },
        { key: "tracking", name: "Tracking", icon: "cis-queue" },
        { key: "lading_bill", name: "Lading bill", icon: "cis-truck" },
        { key: "owning_box", name: "SKU list", icon: "cis-box" },
        { key: "compensation", name: "Compensation", icon: "cis-library" },
        { key: "logs", name: "Logs", icon: "cis-note" },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store
        .dispatch("order.order_wholesale.detail.fetch", this.id)
        .catch(() => {
          this.$router.push("/sale/orders/wholesale");
        });
      this.$store.dispatch("order.order_items_wholesale.push-query", {
        "filter[order_id]": this.id,
      });
    }
  },
  computed: {
    ...mapGetters({
      items: "order.order_items_wholesale.list",
      wholesale: "order.order_wholesale.detail",
      ladingBills: "warehouse.lading_bills_of_orders.list",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
